if (!Math.sign) {
  Math.sign = function (x) {
    return ((x > 0) - (x < 0)) || +x;
  };
}

if (!Math.trunc) {
  Math.trunc = function (v) {
    v = +v;
    return (v - v % 1) || (!isFinite(v) || v === 0 ? v : v < 0 ? -0 : 0);
  };
}